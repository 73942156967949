import axios from 'axios';
import utils from '../utils.js'
export default {
	async listSessions(data){
		const flags = !data.flags ? '' : `&flags=${data.flags}`;
		const subset = !data.subset_flags ? '' : `&subset=${data.subset_flags}`;
		let url = `/api/session/?page=${data.page_number}&page_size=${data.items_per_page}&called_from=${data.called_from}&from_date=${data.from_date}
		&to_date=${data.to_date}&search_by_name=${data.search_by_name}&active_tab=${data.active_tab}${flags}${subset}`

		if (data.sort_by){
			url += `&sort_by=${data.sort_by}&descending=${data.descending}`
		}
		try {
			const response = await axios.get(url);
			return response.data;
		}catch(error) {
			return utils.getError(error)
		}
	},
	async rescheduleSession(session_id, data) {
		try {
			await axios.put(`/api/session/${session_id}/`,data)
		}catch(error) {
			return utils.getError(error)
		}
	},
	async deleteSession(session_id) {
		try {
				await axios.delete(`/api/session/${session_id}/`)
		}catch(error) {
			return utils.getError(error)
		}
	},
	async deleteSessionsRecurrent(session_id) {
		try {
			await axios.delete(`/api/session/recurrent/${session_id}/`)
		}catch(error) {
			return utils.getError(error)
		}
	},
	async deleteAllSessions(active_tab) {
		try {
			await axios.delete(`/api/session/delete_all/${active_tab}/`)
		}catch(error) {
			return utils.getError(error)
		}
	},

	async getScheduleSessionCountry() {
		try {
			const response = await axios.get(`/api/session/holidays/countries/`)
			return response.data;
		}catch(error) {
			return utils.getError(error)
		}
	},
	async getScheduleSessionCountrySubdivisions(selected_country) {
		try {
			const response = await axios.get(`/api/session/holidays/country_subdivisions/?country=${selected_country}`);
			return response.data;
		}catch(error) {
			return utils.getError(error)
		}
	},

	async getHolidays(country, subdivision) {
		try {
			const response = await axios.get(`/api/session/holidays/?country=${country}&subdivision=${subdivision}`);
			return response.data;
		}catch(error) {
			return utils.getError(error)
		}
	},

	async checkScheduledSession(check_weekend, check_holiday, selected_country, selected_subdivision){
		try {
			const response = await axios.get(`/api/session/holidays/dates/validate/?check_weekend=${check_weekend}&check_holiday=${check_holiday}&selected_country=${selected_country}&selected_subdivision=${selected_subdivision}`);
			return response.data;
		}catch(error) {
			return utils.getError(error)
		}
	}
}

