<template>
	<div class="row justify-end">
		<div v-if="number_of_items_per_page" class="row items-center">
			<span class="q-mr-sm" style="font-size:12px;">Records per page: </span>
			<q-select
				borderless
				v-model="items_per_page"
				:options="items_per_page_options"
			/>
		</div>
		 <q-pagination
			color="grey-8"
			active-color="primary"
			v-model="page_number"
			:max="page_count"
			:max-pages="5"
			direction-links
			boundary-links
		/>
	</div>
</template>

<script>
export default {
	name: "ReportsPagination",
	props:{
		number_of_pages: Number,
		items_per_page_options: Array,
		number_of_items_per_page: Number,
		new_page_number: Number,
	},
	data (){
		return {
			items_per_page: this.number_of_items_per_page,
			page_number: 1,
			page_count: 0,
		}
	},
	methods: {
		changePage(page_number){
			this.page_number = page_number;
			this.$emit('change-page', this.page_number);
		},
	},
	watch: {
		items_per_page: function() {
			this.page_number = 1;
			if(this.items_per_page){
				this.$emit('change-page-number', this.items_per_page);
			}
		},
		new_page_number: function(){
			this.page_number = this.new_page_number
		},
		number_of_pages: function() {
			this.page_count = this.number_of_pages
		},
		page_number: function(){
			this.$emit('change-page', this.page_number);
		}
	},
	created(){
		if (this.number_of_pages){
			this.page_count = this.number_of_pages
		}
	}
}
</script>
w