<template>
	<div :class="$q.screen.gt.xs ? 'q-pa-xl' : 'q-pa-sm'">
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">
			Message Reports
		</h1>
		<q-table
			:data="sessions"
			:columns="$q.screen.gt.xs ? columns : columns_smaller_screen"
			row-key="message_id"
			:max="number_of_pages"
			:rows-per-page-options="[5, 10, 20, 50, 100, 0]"
			:pagination.sync="pagination"
			:loading="loading"
			binary-state-sort
			@request="onRequest"
		>
			<template v-slot:top>
				<div class="flex flex-wrap items-center q-mb-md">
					<q-btn icon="filter_list" @click="show_filters=true" round flat>
						<q-tooltip anchor="top middle" :delay="500">
							Filter
						</q-tooltip>
					</q-btn>

					<q-chip
						outline
						color="primary"
					>
						<span v-if="selected_values_date.value == 'Custom dates'">
							<span v-if="selected_date_from" class="q-ml-xs">
								from {{formatDate(selected_date_from)}}
							</span>
							<span v-if="selected_date_to" class="q-ml-xs">
								to {{formatDate(selected_date_to)}}
							</span>

						</span>
						<span v-else>
							{{selected_values_date.value}}
						</span>
					</q-chip>
					<span v-for="selected_value_in in selected_values_invocation" :key="selected_value_in.label">
						<q-chip
							removable
							outline
							color="primary"
							:label="selected_value_in.label"
							@remove="removeItem(selected_value_in, 'invocation')"
						/>
					</span>
					<span v-for="selected_value_sub in selected_values_subset" :key="selected_value_sub.label">
						<q-chip
							v-if="active_tab != 'real'"
							removable
							outline
							color="primary"
							:label="selected_value_sub.label"
							@remove="removeItem(selected_value_sub, 'subset')"
						/>
					</span>
				</div>
				<div class="row full-width">
					<div class="col-md-6 col-12 items-baseline">
						<q-input
							v-model="search_by_name"
							label="Search by message name"
							outlined
							:hint="search_by_name && search_by_name.length < 3 ? 'Enter at least 3 characters' : ''"
							@input="searchByName"
						>
							<template v-slot:append>
								<q-icon name="search" />
							</template>
						</q-input>
					</div>

					<div class="col-md-6 col-12 flex justify-end items-end">
						<Tabs @open-tab="openTab" />
					</div>
				</div>
			</template>
			<template v-slot:body-cell-reports="props">
				<q-td :props="props">
					<q-btn
						v-if="
							logged_user.customer_permission == 'ADMIN' || (
								logged_user.customer_permission == 'OPERATOR' &&
									(current_CG.root_customer_group_permissions.indexOf(operator_ea1) > -1 ||
									current_CG.root_customer_group_permissions.indexOf(operator_ea2) > -1)
							)
						"
						flat
						round
						color="dark"
						:to="{ name: 'Dispatch Details', params: {id: props.row.id }}"
						icon="list_alt"
					>
						<q-tooltip anchor="top middle" :delay="500">
							Details
						</q-tooltip>
					</q-btn>
					<q-btn
						flat
						round
						color="dark"
						:to="{ name: 'Dispatch Overview', params: {id: props.row.id }}"
						icon="bar_chart"
					>
						<q-tooltip anchor="top middle" :delay="500">
							Overview
						</q-tooltip>
					</q-btn>
				</q-td>
			</template>
			<template v-slot:header-cell-date_start="props">
				<q-th :props="props">
					<q-tooltip anchor="top middle" :delay="500">
						{{ preferred_timezone_preview }}
					</q-tooltip>
					{{ props.col.label }}
				</q-th>
			</template>
			<template v-slot:body-cell-name="props">
				<q-td :props="props">
					{{props.value}}
					<q-tooltip anchor="top middle" :delay="500">
						{{props.value}}
					</q-tooltip>
				</q-td>
			</template>
			<template v-slot:pagination>
				<span class="row items-center justify-end">
					<q-pagination
						v-model="page_number"
						:max="number_of_pages"
						:max-pages="6"
						size="md"
						color="grey-8"
						active-color="primary"
						direction-links
						boundary-links
						@click="changePage(page_number)"
					/>
				</span>
			</template>
		</q-table>

		<q-dialog v-model="show_filters">
			<MessageFilters
				:selected_values_date="selected_values_date"
				:selected_date_to="selected_date_to"
				:selected_date_from="selected_date_from"
				:selected_values_invocation="selected_values_invocation"
				:selected_values_subset="selected_values_subset"
				:active_tab="active_tab"
				:message_reports="true"
				@cancel="show_filters=false"
				@filter-sessions-by-method="filterSessionsByMethod"
				@date-from-selected="dateFromSelected"
				@date-to-selected="dateToSelected"
				@show-datepicker="showDatepicker"
				@reset-date="resetDate"
				@get-filtered-data="getAllSessions"
			/>
		</q-dialog>
		<div v-if="sessions.length" class="field-fab-wrapper">
			<q-btn
				v-if="$q.screen.gt.sm"
				rounded
				padding="md lg"
				class="q-mt-md"
				color="primary"
				icon-right="download"
				label="Export Summary"
				@click="generateSummaryExport()"
			/>
			<q-btn
				v-else
				round
				class="q-mt-md"
				icon="download"
				color="primary"
				size="lg"
				@click.native="generateSummaryExport()"
			>
				<q-tooltip anchor="top middle" :delay="500">
					Export Summary
				</q-tooltip>
			</q-btn>
		</div>
	</div>
</template>

<script>
import Tabs from '@/components/scenarios/utils/Tabs.vue';
import MessageFilters from '@/components/utils/Filters.vue';
import ReportsAPI from '@/services/api/Reports.js';
import SessionsAPI from '@/services/api/Sessions.js';
import moment from 'moment-timezone';
import { mapGetters } from "vuex";
import Pagination from "@/components/utils/Pagination.vue";

export default {
	name: 'MessageReports',
	meta: {
		title: 'Alert Cascade - Message Reports'
	},
	components: {
		Tabs,
		MessageFilters,
		Pagination,
	},
	data() {
		return {
			sessions: [],
			from_date: undefined,
			to_date: undefined,
			active_tab: 'test',
			page_number: 1,
			items_per_page: 20,
			count: 0,
			has_data: true,
			order_type: "ascending",
			order_by: {},
			flags: "",
			subset_flags: "",
			show_datepicker: false,
			loading: false,
			file: {
				'message': '',
				'generating': false,
				'file_error': false,
			},
			order_by_options_sessions: {
				'name': {'option_value': 'name', 'option_text': 'Name'},
				'date_start': {'option_value': 'date_start', 'option_text': 'Date sent'},
				'message_id': {'option_value': 'message_id', 'option_text': 'Message ID'},
				'started_by': {'option_value': 'started_by', 'option_text': 'Started by'}
			},
			selected_values_invocation: [],
			selected_values_subset: [],
			selected_values_date: [],
			show_filters: false,
			selected_date_from: '',
			selected_date_to: '',
			operator_ea1: 'Allow Operators to view address string (detailed reports)',
			operator_ea2: 'Allow Operators to view all info (detailed reports)',
			preferred_timezone: "",
			preferred_timezone_preview: "",
			pagination: {
				rowsPerPage: 20,
				rowsNumber: 0,
				sortBy: 'created_at',
				descending: true,
				page: 1,
			},
			columns_smaller_screen: [
				{
					name: 'name',
					field: 'name',
					align: 'left',
					label: 'Name',
					sortable: true,
					classes: 'ellipsis',
					style: 'max-width: 150px',
					headerStyle: 'max-width: 150px',
				},
				{
					name: 'reports',
					align: 'left',
					label: 'Reports',
					classes: 'ellipsis',
					style: 'width: 110px',
					headerStyle: 'width: 110px',
				},
			],
			columns: [
				{
					name: 'message_id',
					field: 'id',
					align: 'left',
					label: 'Message ID',
					sortable: true,
					classes: 'ellipsis',
					style: 'width: 115px',
					headerStyle: 'width: 115px',
				},
				{
					name: 'name',
					field: 'name',
					align: 'left',
					label: 'Name',
					sortable: true,
					classes: 'ellipsis',
					style: 'max-width: 150px',
					headerStyle: 'max-width: 150px',
				},
				{
					name: 'started_by',
					field: 'started_by',
					align: 'left',
					label: 'Sent by',
					classes: 'ellipsis',
					style: 'max-width: 200px',
					headerStyle: 'max-width: 200px',
					classes: 'ellipsis',
					style: 'width: 135px',
					headerStyle: 'width: 135px',
					sortable: true,
				},
				{
					name: 'date_start',
					field: obj => {
						return moment.utc(obj.date_start).tz(this.preferred_timezone).format('DD/MM/YYYY HH:mm')
					},
					align: 'left',
					label: 'Start date and time',
					sortable: true,
					classes: 'ellipsis',
					style: 'width: 135px',
					headerStyle: 'width: 135px',
				},
				{
					name: 'reports',
					align: 'center',
					label: 'Reports',
					classes: 'ellipsis',
					style: 'width: 110px',
					headerStyle: 'width: 110px',
				},
			],
			search_by_name: '',
		};
	},
	computed: {
		...mapGetters(['logged_user', 'current_CG']),
		number_of_pages: function () {
			return Math.ceil(this.count / this.items_per_page);
		}
	},
	methods: {
		async changePage(page_number) {
			this.page_number = page_number;
			await this.getAllSessions();
		},
		async changePageNumber(num_pages) {
			this.items_per_page = num_pages === 0 ? this.items_per_page = this.count : num_pages;
			this.page_number = 1;
			await this.getAllSessions();
		},
		async openTab(active_tab) {
			this.active_tab = active_tab;
			this.page_number = 1;
			await this.getAllSessions();
		},
		dateFromSelected(new_val) {
			if (new_val) {
				this.selected_values_date = new_val.type
				if (this.selected_values_date.value == 'Custom dates'){
					this.selected_date_from = new_val.value
				}
				this.from_date = new_val.value
				this.page_number = 1;
				this.to_date = moment().format('YYYY-MM-DD');
				this.selected_date_to = moment().format('YYYY-MM-DD');
			}
		},
		dateToSelected(new_val) {
			this.selected_date_to = new_val
			this.page_number = 1;
			this.to_date = new_val;
		},
		showDatepicker(value){
			this.show_datepicker = value
		},
		resetDate() {
			this.page_number = 1;
			this.selected_values_date = { value: 'All date ranges' }
			this.from_date = undefined;
			this.to_date = undefined;
		},
		async changeOrder(data){
			this.page_number = 1;
			this.order_type = data.order_type
			let obj = {};
			obj['sessions_ordering'] = this.order_by_options_sessions[data.order_by];
			obj['sessions_order_type'] = this.order_type;
			await ReportsAPI.updateUserScenarioOrdering(obj);
			await this.getAllSessions();
		},
		filterSessionsByMethod(type, flags){
			let value = []
			for (let obj of flags){
				value.push(obj.type)
			}
			if (type=='invocation'){
				this.selected_values_invocation = flags
				this.flags = value;
			}else{
				this.selected_values_subset = flags
				this.subset_flags = value;
			}
			this.page_number = 1;
		},
		async searchByName(){
			if (this.search_by_name.length >= 3 || this.search_by_name.length == 0){
				this.page_number = 1
				await this.getAllSessions()
			}
		},
		async getAllSessions() {
			if (!this.show_datepicker || (this.show_datepicker && this.from_date)){
				this.loading = true
				let data = {
					'page_number': this.page_number,
					'items_per_page': this.items_per_page,
					'called_from': 'message',
					'from_date': this.from_date,
					'to_date': this.to_date,
					'active_tab': this.active_tab,
					'flags': this.flags,
					'subset_flags': this.subset_flags,
					'search_by_name': this.search_by_name,
				}
				const response = await SessionsAPI.listSessions(data)
				this.sessions = response.results.sessions;
				this.count = response.count;
				this.loading = false
				// check if there are no sessions
				this.has_data = response.count > 0;
				this.order_by = response.results.order_by_rule;
				if (!response.results.sessions_order_type) {
					this.order_type = 'ascending';
				} else {
					this.order_type = response.results.sessions_order_type;
				}
			}
		},
		async generateSummaryExport() {
			let formatted_flags = ''
			for (let item of this.flags) {
				formatted_flags += item
			}
			var data = {
				to_date: this.to_date,
				from_date: this.from_date,
				order_by: this.order_by,
				order_type: this.order_type,
				subset_flags: this.subset_flags,
				flags: formatted_flags,
				user_timezone: this.logged_user.preferred_timezone
			};
			const response = await ReportsAPI.generateSummaryExport(data, this.active_tab);
			this.$store.dispatch('set_message_reports_errors', "");
			this.file.generating = true
			if (response.error) {
				this.file.file_error = true
				if (response.statusCode == 500) {
					this.notificationError("Internal Server error 500. Please contact your administrator.")
				} else {
					this.notificationError(response.data)
				}
				return;
			}
			this.file.file_error = false
			this.$q.notify('Your summary export was scheduled. You will receive a message with the file on ' + this.logged_user.email + '.')
		},
		closeTimeoutMessage() {
			this.file.generating = false;
			this.file.message = ''
		},
		notificationError(msg){
			this.$q.notify({
				timeout: 6700,
				message: msg,
				actions: [
					{
						label: 'Dismiss',
						color: 'primary',
					},
				],
			})
		},
		async removeItem(item, type){
			if (type == 'invocation'){
				let index = this.selected_values_invocation.indexOf(item);
				if (index > -1) {
					this.selected_values_invocation.splice(index, 1);
					this.filterSessionsByMethod('invocation', this.selected_values_invocation)
					await this.getAllSessions()
				}
			}else if(type == 'subset'){
				let index = this.selected_values_subset.indexOf(item);
				if (index > -1) {
					this.selected_values_subset.splice(index, 1);
					this.filterSessionsByMethod('subset', this.selected_values_subset)
					await this.getAllSessions()
				}
			}
		},
		formatDate(value){
			return moment.utc(value).format('DD/MM/YYYY')
		},
		onRequest(props) {
			const { rowsPerPage, page, rowsNumber} = props.pagination
			if (props.pagination.sortBy != this.pagination.sortBy ||
				props.pagination.descending != this.pagination.descending){
				this.page_number = 1
				this.pagination.sortBy = props.pagination.sortBy
				this.pagination.descending = props.pagination.descending
				let order_type = this.pagination.descending == false ? 'descending' : 'ascending'
				let data = {
					'order_type': order_type,
					'order_by': this.pagination.sortBy,
				}
				this.changeOrder(data)
			}else if (rowsPerPage || page){
				this.page_number = 1
				this.pagination.rowsPerPage = rowsPerPage
				this.pagination.rowsNumber = rowsNumber
				this.changePageNumber(this.pagination.rowsPerPage)
			}
		},
	},
	async created () {
		let user_settings = JSON.parse(this.logged_user['settings']);
		if(user_settings && user_settings['scenario_filter']){
			if (user_settings['scenario_filter'] == 'Custom dates' && !this.from_date){
				this.selected_values_date = { value: 'Last 30 days' };
				this.from_date = moment().subtract(this.selected_values_date['value'].match(/\d+/)[0], 'days').format('YYYY-MM-DD');
				this.to_date = moment().format('YYYY-MM-DD');
				await ReportsAPI.updateUsersSessionFilters(this.selected_values_date);
			}else{
				this.selected_values_date = { value: user_settings['scenario_filter'] };
				if (user_settings['scenario_filter'] !== 'All date ranges'){
					this.from_date = moment().subtract(user_settings['scenario_filter'].match(/\d+/)[0], 'days').format('YYYY-MM-DD');
					this.to_date = moment().format('YYYY-MM-DD');
				}
			}
		}else{
			this.selected_values_date = { value: 'Last 30 days' };
			this.from_date = moment().subtract(this.selected_values_date['value'].match(/\d+/)[0], 'days').format('YYYY-MM-DD');
			this.to_date = moment().format('YYYY-MM-DD');
			await ReportsAPI.updateUsersSessionFilters(this.selected_values_date);
		}

		if (this.logged_user) {
			this.preferred_timezone = this.logged_user.preferred_timezone;
			this.preferred_timezone_preview = "Timezone: " + this.preferred_timezone;
		}
		await this.getAllSessions();
	},
}
</script>

<style lang="scss" scoped>
	.field-fab-wrapper {
		position: sticky;
		bottom: 20px;
		z-index: 5;
		width: fit-content;
		margin: 10px auto;
	}
</style>
